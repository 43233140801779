// General
import React, { useCallback, useEffect, useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import ParcelComponent from "single-spa-react/parcel";
import {
  isUnity,
  LocalizationNS,
  sessionStorageIdentifiers,
  useFeatureFlag,
  useGetOrganization,
  useGetToken,
  useI18n,
  withI18n,
} from "compass-commons";
// Components
import { AuthWrapper, Button, Alert, AppTitle } from "dms-lib";
import {
  FilterListRounded,
  InsightsRounded,
  ListRounded,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { getOrgIdFromToken } from "compass-shared-services";
import CollapsiblePanel from "./components/commons/collapsiblePanel/CollapsiblePanel";
import FilterPanel from "./components/filterPanel/FilterPanel";
import ResultsPanel from "./components/resultsPanel/ResultsPanel";
import DetailsPanel from "./components/detailsPanel/DetailsPanel";
import ReportCartBadge from "./components/reportCartBadge/ReportCartBadge";
// Styles
import "./App.css";
import "./styles.scss";
// Store
import { StateProvider } from "./contexts/StateContext";
// Services
import StateService from "./services/StateService";
// Models
import { StateHolder } from "./models/state/StateHolder";
import UserManagerService from "./services/UserManagerService";
// Utils
import { SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG } from "./util/Constants";
import {
  getReportCheckoutPath,
  REPORT_CHECKOUT_PATH,
  ROOTPATH,
} from "./router/route";

const stateService: StateService = new StateService();

const App = (): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    selectedIncidentId,
    resultsPanelHidden,
    detailsPanelHidden,
    filterPanelHidden,
    currentGroupInfoSearchResult,
    filterCriteriaHolder,
    currentIncidentSearchResult,
    selectedIncident,
    selectedResultsView,
    alertSubject,
  } = stateService;

  const token = (() => {
    const theToken = useGetToken();
    return isStandalone ? localStorage.getItem("token") : theToken;
  })();

  const orgId = getOrgIdFromToken(token);
  const organizationId = isStandalone
    ? getOrgIdFromToken(token)
    : useGetOrganization();

  const [isAllowedUser, setIsAllowedUser] = useState<boolean>();
  const isSwitchingOrg = organizationId && organizationId !== orgId;

  const isLoggedInUser = Boolean(token);

  const fetchAllowedUser = () => {
    UserManagerService.isUserAllowed().then((r) => {
      setIsAllowedUser(r);
    });
  };

  useEffect(() => {
    if (!isLoggedInUser) return;

    fetchAllowedUser();
  }, [isLoggedInUser, orgId]);

  const setMainHelpLinkKeyword = () => {
    const mainContextHelpKeyword = sessionStorage.getItem(
      sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD
    );

    if (
      !mainContextHelpKeyword ||
      mainContextHelpKeyword !== appConfig.MFE_HELP_LINK_KEYWORD // can I do this?
    ) {
      sessionStorage.setItem(
        sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD,
        appConfig.MFE_HELP_LINK_KEYWORD
      );
    }
  };

  useEffect(() => {
    const subscription = selectedIncidentId.subscribe((value) => {
      if (value != null) {
        detailsPanelHidden.next(false);
        filterPanelHidden.next(true);
      }
    });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [selectedIncidentId, detailsPanelHidden]);

  useEffect(() => {
    setMainHelpLinkKeyword();
  }, []);

  const filterWidget = <FilterPanel />;
  const resultsWidget = <ResultsPanel />;
  const detailsWidget = <DetailsPanel />;

  const checkoutClicked = useCallback(
    (isClicked) => {
      if (isClicked) {
        const state = {} as StateHolder;
        state.filterCriteria = filterCriteriaHolder.value;
        state.incidentSearchResult = currentIncidentSearchResult.value;
        state.groupInfoSearchResult = currentGroupInfoSearchResult.value;
        state.selectedIncident = selectedIncident.value;
        state.resultView = selectedResultsView.value;
        state.org = organizationId;
        sessionStorage.setItem(
          sessionStorageIdentifiers.STATE_HOLDER,
          JSON.stringify(state)
        );
        window.location.href = getReportCheckoutPath();
      }
    },
    [
      organizationId,
      filterCriteriaHolder,
      currentIncidentSearchResult,
      currentGroupInfoSearchResult,
      selectedIncident,
      selectedResultsView,
    ]
  );

  const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(
    appConfig,
    SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG
  );
  const handleRefresh = () => {
    window.location.reload();
  };
  const refreshAction = (
    <Button size="small" color="primary" variant="text" onClick={handleRefresh}>
      Refresh
    </Button>
  );
  useEffect(() => {
    const handleReject = () => {
      if (!showErrorMessagesFeatureFlag) return;
      alertSubject.next({
        title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
        description: translate("genericErrorSubtitle", {
          ns: LocalizationNS.SHARED,
        }),
        action: refreshAction,
      });
    };
    window.addEventListener("unhandledrejection", handleReject);
    return () => {
      window.removeEventListener("unhandledrejection", handleReject);
    };
  }, [showErrorMessagesFeatureFlag]);

  const isStandaloneMode = () => {
    return isStandalone != null && isStandalone;
  };

  useEffect(() => {
    if (isSwitchingOrg) {
      stateService.clearStates();
    }
  }, [isSwitchingOrg]);

  const Content = () => {
    return (
      <>
        <AuthWrapper
          isAuthorized={isAllowedUser || !!isStandalone}
          isLoading={isStandalone ? false : isAllowedUser === undefined}
          unauthorizedTitle={translate("unauthorized", {
            ns: LocalizationNS.SHARED,
          })}
          unauthorizedDescription={translate("unauthorizedContact", {
            ns: LocalizationNS.SHARED,
          })}
        >
          <div className="data-explorer-root">
            {isSwitchingOrg ? (
              <div className="dataexplorer-loading-container">
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="header">
                  <div>
                    <span>{translate("title")}</span>
                  </div>
                  <ReportCartBadge
                    reportCartSubject={stateService.currentReportCart}
                    badgeErrorMessageSubject={
                      stateService.badgeAlreadyHasSelectedItemMessage
                    }
                    onClickCallBack={(isClicked: boolean) =>
                      checkoutClicked(isClicked)
                    }
                  />
                </div>
                <div className="panels-main">
                  <CollapsiblePanel
                    icon={<FilterListRounded color="primary" />}
                    content={filterWidget}
                    name="Filter Panel"
                    columnClass="left-panel"
                    data-cy="collapsible-panel"
                    hidden={filterPanelHidden}
                    scrollable={false}
                  />
                  <CollapsiblePanel
                    icon={<InsightsRounded color="primary" />}
                    content={resultsWidget}
                    name="Results Panel"
                    columnClass="middle-panel"
                    data-cy="collapsible-panel"
                    hidden={resultsPanelHidden}
                    scrollable={false}
                  />
                  <CollapsiblePanel
                    icon={<ListRounded color="primary" />}
                    content={detailsWidget}
                    name="Details Panel"
                    columnClass="right-panel"
                    data-cy="collapsible-panel"
                    hidden={detailsPanelHidden}
                  />
                </div>
              </>
            )}
          </div>
        </AuthWrapper>
        <Alert alertNotificationSubject={alertSubject} />
      </>
    );
  };
  return (
    (isLoggedInUser || isStandaloneMode()) && (
      <React.StrictMode>
        <StateProvider value={stateService}>
          {isDMS && (
            <AppTitle translate={translate} localizationNS={LocalizationNS} />
          )}
          {isUnity ? (
            <Router>
              <Routes>
                <Route path={ROOTPATH} element={<Content key={orgId} />} />
                <Route
                  path={REPORT_CHECKOUT_PATH}
                  element={() => (
                    <ParcelComponent
                      config={() => System.import("@compass/report-checkout")}
                      wrapWith="section"
                      className="app-container"
                    />
                  )}
                />
              </Routes>
            </Router>
          ) : (
            <Content />
          )}
        </StateProvider>
      </React.StrictMode>
    )
  );
};

export default withI18n(App);
